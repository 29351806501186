type ErrorType = 'story' | 'design' | 'designObjectIdNotFound' | 'shop';

const getErrorMessage = <T>(type: ErrorType, msg?: string, data?: T) => {
  const errors = {
    design: {
      data,
      message: `${msg}`,
      statusCode: 404,
      statusMessage: 'Design not found',
    },
    designObjectIdNotFound: {
      data,
      message: `${msg}`,
      statusCode: 404,
      statusMessage: 'ObjectID does not exist',
    },
    shop: {
      data,
      message: `${msg}`,
      statusCode: 404,
      statusMessage: 'Shop not found',
    },
    story: {
      data,
      message: `${msg}`,
      statusCode: 404,
      statusMessage: 'Story not found',
    },
  };

  return errors[type];
};

export { getErrorMessage };
